import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box, Text } from '@mentimeter/ragnar-ui';
import { capitalize } from '@mentimeter/ragnar-utils';
import React from 'react';

export interface InputFeedbackT {
  id: string;
  error?: string | undefined;
  hint?: React.ReactNode;
  showError: boolean;
  alwaysTakeSpace?: boolean;
  capitalized?: boolean;
  className?: string | undefined;
}

export function InputFeedback({
  id,
  error,
  hint,
  showError,
  alwaysTakeSpace = true,
  capitalized = false,
  className,
}: InputFeedbackT) {
  let msg: React.ReactNode = alwaysTakeSpace ? '\u00A0' : null;

  if (showError) {
    msg = error;
  } else if (hint) {
    msg = hint;
  }

  if (capitalized && typeof msg === 'string') {
    msg = capitalize(msg);
  }

  const msgOrDoesntTakeSpace = msg || !alwaysTakeSpace;
  return (
    <Box
      className={clsx('h-[28px]', msgOrDoesntTakeSpace && 'h-auto', className)}
    >
      <Text
        id={id}
        className={clsx(
          'text-87.5',
          'leading-175',
          'text-weaker',
          showError && 'text-negative',
        )}
      >
        {msg}
      </Text>
    </Box>
  );
}
