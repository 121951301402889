import { Field, type FieldProps } from 'formik';
import React from 'react';
import {
  TooltipDescriptive,
  Label as LabelR,
  SelectItem,
  Box,
  type BoxT,
} from '@mentimeter/ragnar-ui';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { getErrorMessage, getShouldShowError } from './utils';
import { InputFeedback } from './InputFeedback';

export interface SelectT extends Omit<BoxT, 'onBlur' | 'onChange' | 'onFocus'> {
  name: string;
  description?: string;
  label: string;
  hintText?: string;
  disabled?: boolean;
  options: Array<{
    key: string;
    value: string;
    label: string;
    disabled?: boolean;
    selected?: boolean;
  }>;
  validate?: (value: any) => void | string | Promise<any>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  onFocus?: React.FocusEventHandler<HTMLSelectElement>;
  innerRef?: any;
  selectSize?: 'default' | 'compact';
}

export function Select({
  name,
  description,
  label,
  validate,
  innerRef,
  hintText,
  onBlur,
  onFocus,
  onChange,
  options,
  disabled,
  selectSize = 'compact',
  ...boxProps
}: SelectT) {
  return (
    <Field name={name} validate={validate} innerRef={innerRef}>
      {(fieldProps: FieldProps) => {
        const shouldShowErrorMessage = getShouldShowError(fieldProps);
        return (
          <Box {...boxProps}>
            <Box className={clsx(['flex-row', 'mb-2'])}>
              <LabelR htmlFor={name} className="mb-0">
                {label}
              </LabelR>
              {description && (
                <Box className={clsx(['flex-row', 'items-start'])}>
                  <TooltipDescriptive
                    description={description}
                    id={`${name}-label-description`}
                  />
                </Box>
              )}
            </Box>
            <SelectItem
              inputSize={selectSize}
              id={name}
              aria-describedby={`input-feedback-${name}`}
              {...fieldProps.field}
              disabled={disabled}
              onBlur={(e) => {
                fieldProps.field.onBlur(e);
                if (onBlur) onBlur(e);
              }}
              onChange={(e) => {
                fieldProps.field.onChange(e);
                if (onChange) onChange(e);
              }}
              onFocus={onFocus}
              status={shouldShowErrorMessage ? 'error' : undefined}
            >
              {options.map(({ label, ...option }) => (
                <SelectItem.Option {...option} key={option.key}>
                  {label}
                </SelectItem.Option>
              ))}
            </SelectItem>
            <InputFeedback
              error={getErrorMessage(fieldProps)}
              showError={shouldShowErrorMessage}
              hint={hintText}
              id={`input-feedback-${name}`}
            />
          </Box>
        );
      }}
    </Field>
  );
}
